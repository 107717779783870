import React from 'react';

export default function FormTextInput({fieldText, fieldName, required, placeholder, errorText}) {
    if (required) {
        return (
            <div className="required-form-group">
                <div data-js-form-group="" className="f-input_text m-required default-form-group">
                    <div className="default-form-input">
                        <label for="firstName" className="f-input_text-label default-form-text">
                            {fieldText}
                        </label>
                    </div>
                    <div className="default-form-input_container">
                        <div className="default-form-input_container_field">
                            <input type="text" name={fieldName} className="f-input_text-field" aria-label={fieldText} placeholder={placeholder} required/>
                        </div>
                    </div>
                </div>
                <div id={`${fieldName}-error`} data-js-invalid-email-error="" data-analytics-field-error="" className="default-form-input_subtext_error h-hidden">
                    {errorText || "Please provide a valid input"}
                </div>
            </div>
        )
    } else {
        return (
            <div data-js-form-group="" className="f-input_text default-form-group">
                <div className="default-form-input">
                    <label for="firstName" className="f-input_text-label default-form-text">
                        {fieldText} <span className="default-form-secondary-text">(optional)</span>
                    </label>
                </div>
                <div className="default-form-input_container">
                    <div className="default-form-input_container_field">
                    <input type="text" name={fieldName} className="f-input_text-field" placeholder={placeholder} aria-label={fieldText}/>
                    </div>
                </div>
            </div>
        )
    }
};