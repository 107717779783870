import React from 'react';
import '../style/Footer.css';

export default function FnDFooter() {
    return (
        <article className="b-footer_bottom">
            <div className="b-footer_bottom-inner">
                {/* Can look into how we would want to store and use the icons
                <div className="b-footer_bottom-social">
                    <nav className="b-footer_social-list">
                        <a href="http://www.pinterest.com/flooranddecor/" data-js-analytics-prevent="" className="b-footer_social-link"
                        tabindex="0" target="_blank" title="Pinterest">
                            <i className="b-footer_social-icon icon icon-pinterest-before" aria-hidden="true"></i>
                        </a>
                        <a href="https://www.instagram.com/flooranddecor/" data-js-analytics-prevent="" className="b-footer_social-link"
                        tabindex="0" target="_blank" title="Social Instagram Link">
                            <i className="b-footer_social-icon icon icon-instagram-before" aria-hidden="true"></i>
                        </a>
                        <a href="https://www.facebook.com/flooranddecor" data-js-analytics-prevent="" className="b-footer_social-link"
                        tabindex="0" target="_blank" title="FaceBook">
                            <i className="b-footer_social-icon icon icon-facebook-before" aria-hidden="true"></i>
                        </a>
                        <a href="https://twitter.com/flooranddecor" data-js-analytics-prevent="" className="b-footer_social-link"
                        tabindex="0" target="_blank" title="Twitter">
                            <i className="b-footer_social-icon icon icon-twitter-before" aria-hidden="true"></i>
                        </a>
                        <a href="http://www.youtube.com/FloorAndDecor" data-js-analytics-prevent="" className="b-footer_social-link"
                        tabindex="0" target="_blank" title="YouTube">
                            <i className="b-footer_social-icon icon icon-youtube-before" aria-hidden="true"></i>
                        </a>
                        <a href="http://www.houzz.com/pro/flooranddecor/floor-and-decor" data-js-analytics-prevent=""
                        className="b-footer_social-link" tabindex="0" target="_blank" title="Social Houzz Link">
                            <i className="b-footer_social-icon icon icon-houzz-before" aria-hidden="true"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/flooranddecor/" data-js-analytics-prevent=""
                        className="b-footer_social-link" tabindex="0" target="_blank" title="Social Linked In Link">
                            <i className="b-footer_social-icon icon icon-linkedin-before" aria-hidden="true"></i>
                        </a>
                    </nav>
                </div> */}
                <div className="b-footer_bottom-copyright">
                    <div className="b-footer_copyright">
                        © 2014 - <span id="currentyear">2023</span> Floor &amp; Decor. All Rights Reserved.
                    </div>
                </div>
            </div>
        </article>
    )
}