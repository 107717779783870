import React from 'react';

import fndLogo from '../img/fnd-logo.svg';

export default function FnDHeader() {
    return (
        <header className="l-header js-sticky">
            <div className="l-header-container js-header-wrapper">
                <div className="l-header-hamburger">
                <div className="b-hamburger-button">
                </div>
                </div>
                <div className="l-header-logo">
                <div className="b-logo">
                <a className="b-logo-link" href="http://www.flooranddecor.com" itemProp="url" tabIndex="0">
                    <img
                        src={fndLogo}
                        className="b-logo-img" alt="Floor &amp; Decor" title="Floor &amp; Decor"/>
                    </a>
                </div>
                </div>
            </div>
        </header>
    )
}