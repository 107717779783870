import React from 'react';
import Modal from 'react-modal';
import axios from 'axios';

import StateDropdown from './StateDropdown';
import FormTextInput from './FormTextInput';

import '../style/Form.css';
import '../style/Modal.css';

import {
    fields,
    successMessage,
    errorMessage,
    successRedirect,
} from '../constants/formConstants';

Modal.setAppElement('#root');

const formVals = {};

export default function ServProForm() {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalMessage, setModalMessage] = React.useState(successMessage);
    const [hasErrors, setErrorEncountered] = React.useState(false);
    const [redirectMessage, setRedirectMess] = React.useState(successRedirect);

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    function addErrorOnInput(inputEl) {
        document.getElementById(`${inputEl.name}-error`).classList.remove('h-hidden');
        inputEl.parentElement.classList.add('default-form-input_container_field_error');
    }

    function removeErrorOnInput (inputEl) {
        let errorEl = document.getElementById(`${inputEl.name}-error`);
        if (errorEl) errorEl.classList.add('h-hidden');
        inputEl.parentElement.classList.remove('default-form-input_container_field_error');
    }

    function validateTextFields(errors) {
        const textInputs = document.querySelectorAll(".f-input_text-field");
        textInputs.forEach((formInput) => {
            formVals[formInput.name] = formInput.value;
            removeErrorOnInput(formInput);
            if (formInput.required && !formInput.value) {
                addErrorOnInput(formInput);
                errors = true;
            }
        });
        return errors;
    }

    function validateStateDropdown(errors) {
        const stateDropdown = document.querySelector(".f-select-element");
        formVals[stateDropdown.name] = stateDropdown.value;
        if (!stateDropdown.value) {
            document.getElementById('state-error').classList.remove('h-hidden');
            stateDropdown.classList.add('default-form-input_container_field_error');
            errors = true;
        } else {
            document.getElementById('state-error').classList.add('h-hidden');
            stateDropdown.classList.remove('default-form-input_container_field_error');
        }
        return errors;
    }

    function validateAndSubmitForm(event) {
        event.preventDefault();
        let errors = validateTextFields(false);
        errors = validateStateDropdown(errors);
        setErrorEncountered(errors);

        if (!errors) {
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_HOSTNAME + '/api/contact',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true',
                    'Connection': 'keep-alive'
                },
                data: formVals
            })
                .then (async function (response) {
                    setModalMessage(successMessage);
                    //await delay(15000);
                    //window.location.href = "http://www.flooranddecor.com";
                })
                .catch(async function (error) {
                    console.error(`An error was encountered when sending the email. Error: ${error}`);
                    setModalMessage(errorMessage);
                    setRedirectMess('');
                    setErrorEncountered(true);
                    await delay(1000);
                })
                .finally (
                    setIsOpen(true)
                );
        }
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    function handleModalClose() {
        if (!hasErrors) {
            window.location.href = "http://www.flooranddecor.com";
        }
        setIsOpen(false);
    }

    return (
        <div className="default-form-section">
            <div className="default-form-subtext">
              Creating an account is fast and easy. Please fill in the following fields.
            </div>
            <form id="default-form"
              action="/on/demandware.store/Sites-floor-decor-Site/default/AccountRedesign-SubmitRegistration" method="POST">
              {fields.map(field => (
                <FormTextInput fieldText={field.fieldText} fieldName={field.fieldName} required={field.required} placeholder={field.placeholder} />
              ))}
                <StateDropdown fieldName='state' />
                <FormTextInput fieldText="Zip Code" fieldName="zipCode" required="true" />
                <button onClick={validateAndSubmitForm} className="default-button">
                    SUBMIT
                </button>
                <div id='ServProModal'>
                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        contentLabel="Example Modal"
                        className="modal"
                        overlayClassName="overlay"
                    >
                        <div className='modal-content'>
                            <div className='modal-width-control'>
                                <h2 className='modal-header' ref={(_subtitle) => (subtitle = _subtitle)}>Thank You!</h2>
                                <div className='modal-text'>
                                    {modalMessage}
                                    <br/>
                                    <br/>
                                    {redirectMessage}
                                </div>
                                <button className='default-button' onClick={handleModalClose}>OK</button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </form>
          </div>
    )
};
