import './App.css';
import React  from 'react';
import FnDHeader from './components/FnDHeader';
import ServProForm from './components/ServProForm';
import servproLogo from './img/servpro-logo.png';
import RedDivider from './components/RedDivider';
import FnDFooter from './components/FnDFooter';

function App() {
  return (
    <div id='ServProPage' className="App">
      <FnDHeader />
      <RedDivider />
      <div className="main-body">
        <div className="content-header">
          <img src={servproLogo} alt="servpro-logo"/>
        </div>
        <div className="content-section">
          <ServProForm />
        </div>
      </div>
      <RedDivider text="YOU HAVE TO SEE IT TO BELIEVE IT®" />
      <FnDFooter />
    </div>
  );
}

export default App;
