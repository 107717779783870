export const fields = [
    {fieldText: "First Name", fieldName: "firstName", required: true},
    {fieldText: "Last Name", fieldName: "lastName", required: true},
    {fieldText: "Company Name", fieldName: "companyName", required: true},
    {fieldText: "ServPro Franchise #", fieldName: "franchiseNumber", required: true},
    {fieldText: "Email Address", fieldName: "email", required: true},
    {fieldText: "Phone", fieldName: "phone", required: true, placeholder: "333-333-3333"},
    {fieldText: "Address 1", fieldName: "address1", required: true},
    {fieldText: "Address 2", fieldName: "address2", required: false},
    {fieldText: "City", fieldName: "city", required: true}
]

export const successMessage = "Thank you for submitting your information! A Floor & Decor Regional Manager will be in touch with you shortly."
export const successRedirect = "Click OK to visit flooranddecor.com now"

export const errorMessage = 'An error occured when sending the email. Please try again later.'

// module.exports = fields;
